import React, { useState } from "react";

import pmt1 from "../../images/checkout/img-1.png";
import pmt2 from "../../images/checkout/img-2.png";
import pmt3 from "../../images/checkout/img-3.png";
import pmt4 from "../../images/checkout/img-4.png";
import "./style.css";
import SessionApi from "../../Apis/SessionApi";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const stripePromise = loadStripe(
  "pk_live_51QEAY5GDPsph5cIJf5TkkoBnUXIi4PUvciOGiFB3ejROo72WdyvS2ivBaZ3Rihq0OtOwFO3ReXLhfe33vnIPNdIu00uLZ9cuCi"
);

const Donate = (props) => {
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const createSession = async () => {
    if (!amount) {
      toast.error("please fill in amount");
      return;
    }
    setLoading(true);
    const response = await SessionApi.handleCheckOut(amount*100);
    if (response.success) {
      setClientSecret(response.data);
    } else {
      toast.error("something went wrong");
    }
    setLoading(false);
  };

  const options = { clientSecret };
  
  return (
    <div className="wpo-donation-page-area section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="wpo-donate-header">
              <h2>Doe een Donatie</h2>
            </div>
            <form onSubmit={SubmitHandler} action="#">
              <div className="wpo-donations-amount">
                <h2>Uw Donatie</h2>
                <input
                  type="text"
                  className="form-control"
                  name="text"
                  id="text"
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder="Voer het Donatiebedrag in"
                />
              </div>
              <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={options}
              >
                <EmbeddedCheckout />
              </EmbeddedCheckoutProvider>
              {/* <div className="wpo-donations-details">
                                <h2>Gegevens</h2>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                                        <input type="text" className="form-control" name="name" id="fname" placeholder="Voornaam"/>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                                        <input type="text" className="form-control" name="name" id="name" placeholder="Achternaam"/>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group clearfix">
                                        <input type="email" className="form-control" name="email" id="email" placeholder="E-mail"/>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                                        <input type="text" className="form-control" name="Adress" id="Adress" placeholder="Adres"/>
                                    </div>
                                    <div className="col-lg-12 col-12 form-group">
                                        <textarea className="form-control" name="note" id="note" placeholder="Bericht"></textarea>
                                    </div>
                                </div>
                            </div> */}
              {/* <div className="wpo-doanation-payment">
                                <h2>Kies uw Betalingsmethode</h2>
                                <div className="wpo-payment-area">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="wpo-payment-option" id="open4">
                                                <div className="wpo-payment-select">
                                                    <ul>
                                                        <li className="addToggle">
                                                            <input id="add"  type="radio" name="payment" value="30"/>
                                                            <label htmlFor="add">Betaling met Kaart</label>
                                                        </li>
                                                        <li className="removeToggle">
                                                            <input id="remove" type="radio" name="payment" value="30"/>
                                                            <label htmlFor="remove">Offline Donatie</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div id="open5" className="payment-name">
                                                    <ul>
                                                        <li className="visa"><input id="1" type="radio" name="size" value="30"/>
                                                            <label htmlFor="1"><img src={pmt1} alt=""/></label>
                                                        </li>
                                                        <li className="mas"><input id="2" type="radio" name="size" value="30"/>
                                                            <label htmlFor="2"><img src={pmt2} alt=""/></label>
                                                        </li>
                                                        <li className="ski"><input id="3" type="radio" name="size" value="30"/>
                                                            <label htmlFor="3"><img src={pmt3} alt=""/></label>
                                                        </li>
                                                        <li className="pay"><input id="4" type="radio" name="size" value="30"/>
                                                            <label htmlFor="4"><img src={pmt4} alt=""/></label>
                                                        </li>
                                                    </ul>
                                                    <div className="contact-form form-style">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-12 col-12">
                                                                <label>Naam Kaarthouder</label>
                                                                <input type="text" placeholder="" name="name"/>
                                                            </div>
                                                            <div className="col-lg-6 col-md-12 col-12">
                                                                <label>Kaartnummer</label>
                                                                <input type="text" placeholder="" id="card" name="card"/>
                                                            </div>
                                                            <div className="col-lg-6 col-md-12 col-12">
                                                                <label>CVV</label>
                                                                <input type="text" placeholder="" name="CVV"/>
                                                            </div>
                                                            <div className="col-lg-6 col-md-12 col-12">
                                                                <label>Vervaldatum</label>
                                                                <input type="text" placeholder="" name="date"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
              {!clientSecret && (
                <div className="submit-area">
                  <button
                    type="button"
                    className="theme-btn submit-btn btn-donate"
                    disabled={loading}
                    onClick={createSession}
                  >
                    {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Doneer Nu"}
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Donate;
